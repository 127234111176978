<div class="book-viewer" *ngIf="sharedBookProject">
  <header class="book-header">
    <h1 class="book-title">{{ sharedBookProject.title }}</h1>
    <p class="book-author">By {{ sharedBookProject.author }}</p>
    <div id="google_translate_element" class="translate-element"></div>
  </header>

  <div class="book-content">
    <div class="chapters-nav">
      <ul>
        <li *ngFor="let chapter of sharedBookProject.chapters; let i = index">
          <a (click)="scrollToChapter($event, i)" [class.active]="currentChapter === i">{{ chapter.title }}</a>
        </li>
      </ul>
    </div>

    <div class="chapters-content">
      <div class="chapter" *ngFor="let chapter of sharedBookProject.chapters; let i = index" [id]="'chapter-' + i">
        <h2 class="chapter-title">{{ chapter.title }}</h2>
        <div class="chapter-content">
          <p *ngFor="let paragraph of splitIntoParagraphs(chapter.fullText)" [innerHTML]="preserveNewlines(paragraph)"></p>
        </div>
      </div>
    </div>
  </div>
</div>
