import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BookProjectChatApiService } from "../../core/services/book-project-chat-api.service";
import { BookProjectContextService, BookProjectContext } from "../../core/services/book-project-context.service";
import { Subscription, Observable, debounceTime, distinctUntilChanged } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { WalletService } from "../../core/services/wallet.service";
import { CreditsService } from "../../core/services/credits-service";
import * as Sentry from "@sentry/browser";
import { SafeHtml } from '@angular/platform-browser';
import { CostIndication } from "../../core/models/generation.model";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { ChatService, ChatMessage } from "../../core/services/chat.service";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('chatHistory') private chatHistoryElem: ElementRef;
  @ViewChild('messageInput') private messageInput: ElementRef;

  chatForm: FormGroup;
  chatHistory$: Observable<ChatMessage[]>;
  costIndication: CostIndication;
  isLoading: boolean = false;
  currentResponse: string = '';
  isChatVisible: boolean = false;
  selectedModel: string;
  private subscriptions: Subscription[] = [];
  private currentContext: BookProjectContext | null = null;
  private generationSubscription: Subscription;
  private localChatHistory: ChatMessage[] = [];

  constructor(
    private fb: FormBuilder,
    private chatApiService: BookProjectChatApiService,
    private toastr: ToastrService,
    private bookProjectContextService: BookProjectContextService,
    private walletService: WalletService,
    private creditsService: CreditsService,
    private renderer: Renderer2,
    private googleAnalyticsService: GoogleAnalyticsService,
    private chatService: ChatService
  ) {}

  ngOnInit() {
    this.chatForm = this.fb.group({
      message: ['', Validators.required],
      useEntireBookContext: [true],
      highQuality: [false],
      model: ['anthropic/claude-3-haiku:beta']
    });

    this.chatHistory$ = this.chatService.chatHistory$;

    this.subscriptions.push(
      this.chatHistory$.subscribe(history => {
        this.localChatHistory = history;
        this.scrollToBottom();
      })
    );

    this.subscriptions.push(
      this.bookProjectContextService.context$.subscribe(context => {
        this.currentContext = context;
        this.updateCostIndication();
      })
    );

    this.subscriptions.push(
      this.chatService.showChat$.subscribe(showChat => {
        this.isChatVisible = showChat;
        if (showChat) {
          this.updateCostIndication();
        }
      })
    );

    this.subscriptions.push(
      this.chatService.settings$.subscribe(settings => {
        this.chatForm.patchValue({
          useEntireBookContext: settings.useEntireBookContext,
          model: settings.model,
          highQuality: this.chatService.isHighQuality
        }, { emitEvent: false });
        this.selectedModel = settings.model;
        this.updateCostIndication();
      })
    );

    this.initializeUpdateCostIndication();
    this.initializeSettingsChanges();
  }

  ngAfterViewInit() {
    this.updateCostIndication();
    this.setupMessageInputKeyEvent();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.generationSubscription) {
      this.generationSubscription.unsubscribe();
    }
  }

  private setupMessageInputKeyEvent() {
    if (this.messageInput && this.messageInput.nativeElement) {
      this.renderer.listen(this.messageInput.nativeElement, 'keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          this.onSubmit();
        }
      });
    }
  }

  private initializeUpdateCostIndication() {
    this.subscriptions.push(
      this.chatForm.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged((prev, curr) =>
          prev.message === curr.message &&
          prev.useEntireBookContext === curr.useEntireBookContext &&
          prev.model === curr.model
        )
      ).subscribe(() => {
        this.updateCostIndication();
      })
    );
  }

  private initializeSettingsChanges() {
    this.subscriptions.push(
      this.chatForm.get('useEntireBookContext').valueChanges.subscribe(value => {
        this.chatService.updateSettings({ useEntireBookContext: value });
      })
    );

    this.subscriptions.push(
      this.chatForm.get('highQuality').valueChanges.subscribe(isHighQuality => {
        const newModel = isHighQuality ? 'anthropic/claude-3.5-sonnet:beta' : 'anthropic/claude-3-haiku:beta';
        this.chatService.setModel(newModel);
      })
    );
  }

  updateCostIndication() {
    if (this.currentContext) {
      const { message, useEntireBookContext, model } = this.chatForm.value;
      this.chatApiService.getChatCostIndication(
        this.currentContext.bookProjectId,
        message,
        this.currentContext.page,
        useEntireBookContext,
        this.currentContext.specificPartId,
        undefined,
        this.localChatHistory,
        model
      ).subscribe({
        next: (cost) => {
          this.costIndication = cost;
        },
        error: (error) => {
          console.error('Failed to get cost indication', error);
          Sentry.captureException(error);
        }
      });
    }
  }

  onSubmit() {
    if (this.chatForm.valid && !this.isLoading && this.currentContext) {
      this.sendMessage();
    } else if (!this.currentContext) {
      this.toastr.error('Unable to send message. Book project context is not available.');
    }
  }

  sendMessage() {
    if (!this.currentContext) {
      this.toastr.error('Unable to send message. Book project context is not available.');
      return;
    }

    this.googleAnalyticsService.event('chat', 'send_message', this.currentContext.bookProjectId);

    const { message, useEntireBookContext, model } = this.chatForm.value;
    this.isLoading = true;
    this.currentResponse = '';

    this.chatService.addMessage({ role: 'user', content: message });
    this.chatForm.get('message').disable();

    this.walletService.subtractCreditsLocally(this.costIndication.maxCost);
    this.chatForm.patchValue({ message: '' });

    let previousChunk = '';
    this.generationSubscription = this.chatApiService.sendChatMessage(
      this.currentContext.bookProjectId,
      message,
      this.currentContext.page,
      useEntireBookContext,
      this.currentContext.specificPartId,
      undefined,
      this.localChatHistory,
      model
    ).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.DownloadProgress) {
          const chunk = event.partialText || '';
          const newContent = chunk.substring(previousChunk.length);
          this.currentResponse += newContent;
          previousChunk = chunk;
          this.scrollToBottom();
        }
      },
      error: (error) => {
        if (error.error === 'Not enough credits') {
          this.creditsService.handleInsufficientCredits();
        } else if (error.error && error.error.startsWith("[PROVIDER_ERROR]")) {
          if (error.error.includes("flagged input")) {
            this.toastr.warning("The input you provided was flagged by the provider for containing inappropriate content. Please try again with different input.", "Provider error", {extendedTimeOut: 10000});
            Sentry.captureMessage('Flagged input while generating chat response', 'warning');
          } else {
            this.toastr.warning("Your input was refused by the provider. This may be due to capacity constraints or other circumstances.", "Provider error", {extendedTimeOut: 10000});
            Sentry.captureMessage('Provider error while generating chat response', 'warning');
          }
        } else {
          this.toastr.error('Failed to send message. Please try again.');
          Sentry.captureException(error);
        }
        this.isLoading = false;
        this.chatForm.get('message').enable();
        this.focusMessageInput();
        this.walletService.fetchWalletFromApiAndNotifyListeners();
      },
      complete: () => {
        if (this.currentResponse) {
          this.chatService.addMessage({ role: 'assistant', content: this.currentResponse });
          this.currentResponse = '';
        }
        this.isLoading = false;
        this.chatForm.get('message').enable();
        this.focusMessageInput();
        this.scrollToBottom();
        this.updateCostIndication();
        this.walletService.fetchWalletFromApiAndNotifyListeners();
      }
    });
  }

  toggleChat() {
    this.chatService.toggleChat();
  }

  clearHistory() {
    this.chatService.clearHistory();
    this.localChatHistory = [];
    this.updateCostIndication();
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.chatHistoryElem) {
        const element = this.chatHistoryElem.nativeElement;
        this.renderer.setProperty(element, 'scrollTop', element.scrollHeight);
      }
    }, 0);
  }

  abortGeneration() {
    if (this.generationSubscription) {
      this.generationSubscription.unsubscribe();
      this.isLoading = false;
      this.toastr.info('Message generation process aborted.');
      this.chatForm.get('message').enable();
      this.updateCostIndication();
    }
  }

  formatMessage(content: string): SafeHtml {
    let formatted = content.replace(/\n/g, '<br>');
    formatted = formatted.replace(/```([^`]+)```/g, '<code>$1</code>');
    formatted = formatted.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '<a href="$2">$1</a>');
    return formatted;
  }

  private focusMessageInput() {
    setTimeout(() => {
      if (this.messageInput && this.messageInput.nativeElement) {
        this.messageInput.nativeElement.focus();
      }
    }, 0);
  }
}
