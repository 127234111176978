import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.PROJECTS.TEXT',
    link: '/dashboard/projects',
    icon: 'bx-edit',
    parentId: 1
  },
  {
    id: 3,
    label: 'MENUITEMS.DISCOVER.TEXT',
    link: '/dashboard/discover',
    icon: 'bx-book-open',
    parentId: 1
  }
];

