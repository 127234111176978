import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { filter, map } from 'rxjs/operators';

export interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
}

@Injectable({
  providedIn: 'root'
})
export class BookProjectChatApiService {
  private apiUrl = `${environment.apiUrl}/chat`;

  constructor(private http: HttpClient) {}

  sendChatMessage(
    bookProjectId: string,
    message: string,
    currentViewingPart: string,
    useEntireBookContext: boolean,
    specificPartId?: string,
    selectedText?: string,
    chatHistory?: ChatMessage[],
    model?: string,
    includeTaleSpinnerContext?: boolean
  ): Observable<any> {
    const payload = {
      bookProjectId: bookProjectId,
      prompt: message,
      useWholeBookContext: useEntireBookContext,
      currentViewingPart: currentViewingPart,
      specificPartId: specificPartId,
      selectedText: selectedText,
      chatHistory: chatHistory,
      model: model,
      includeTaleSpinnerContext: includeTaleSpinnerContext
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'text/event-stream'
    });
    return this.http.post(`${this.apiUrl}`, payload, {
      headers: headers,
      observe: 'events',
      reportProgress: true,
      responseType: 'text'
    }).pipe(
      filter(event => event.type === HttpEventType.DownloadProgress),
      map(event => ({
        type: HttpEventType.DownloadProgress,
        partialText: event['partialText'] || ''
      }))
    );
  }

  getChatCostIndication(
    bookProjectId: string,
    message: string,
    currentViewingPart: string,
    useEntireBookContext: boolean,
    specificPartId?: string,
    selectedText?: string,
    chatHistory?: ChatMessage[],
    model?: string,
    includeTaleSpinnerContext?: boolean
  ): Observable<any> {
    const payload = {
      bookProjectId: bookProjectId,
      prompt: message,
      useWholeBookContext: useEntireBookContext,
      currentViewingPart: currentViewingPart,
      specificPartId: specificPartId,
      selectedText: selectedText,
      chatHistory: chatHistory,
      model: model,
      includeTaleSpinnerContext: includeTaleSpinnerContext
    };
    return this.http.post(`${this.apiUrl}/cost-indication`, payload);
  }
}
