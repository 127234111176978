<div id="layout-wrapper">

  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()" (toggleChatEvent)="toggleChat()">
  </app-topbar>

  <app-sidebar></app-sidebar>

  <div class="main-content">
    <div class="page-content">
      <!-- content -->
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-chat *ngIf="showChat"></app-chat>

  <!-- footer -->
  <app-footer></app-footer>

</div>
