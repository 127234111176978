import {Component, OnInit, Output, EventEmitter, Inject, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {LanguageService} from '../../core/services/language.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../core/services/user.service";
import {AccountService} from "../../core/services/account.service";
import {Account} from "../../core/models/authentication.model";
import {WalletService} from "../../core/services/wallet.service";
import {Subscription} from "rxjs";
import {ProfilePictureService} from "../../core/services/profile-picture-api.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit, OnDestroy {

  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  account: Account;
  private creditsSubscription: Subscription;
  totalCredits: number;
  profilePictureUrl: string;

  constructor(@Inject(DOCUMENT) private document: any, private router: Router,
              public languageService: LanguageService,
              public translate: TranslateService,
              public _cookiesService: CookieService,
              private userService: UserService,
              private accountService: AccountService,
              private walletService: WalletService,
              private profilePictureService: ProfilePictureService) {
  }

  listLang: any = [
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
    {text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es'},
    {text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de'},
    {text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it'},
    {text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru'},
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.accountService.getAccount().pipe()
      .subscribe(account => this.account = account);

    this.creditsSubscription = this.walletService.totalCredits$.subscribe(
      totalCredits => {
        if (totalCredits !== null) {
          this.totalCredits = totalCredits;
        } else {
          this.totalCredits = null;
        }
      }
    );

    this.profilePictureService.getProfilePicture(true).subscribe({
      next: profilePicture => {
        if (profilePicture) {
          this.profilePictureUrl = 'data:image/jpeg;base64,' + profilePicture.imageDataBase64;
        } else {
          this.profilePictureUrl = '';
        }
      }
    });

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.userService.logout();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.creditsSubscription) {
      this.creditsSubscription.unsubscribe();
    }
  }
}
