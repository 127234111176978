<footer class="footer">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-6">
        {{ year }} © Talespinner.
        <span style="margin-left: 8px; margin-right: 8px; cursor: pointer;"
              tooltip="Operated by Bons AI
                       KVK: 91727286 | BTW: 067952045B01
                       Raapopseweg 74, 6824DT Arnhem
                       contact@talespinner.io"
              placement="top"
              triggers="click">
          <i class="fa fa-info-circle"></i>
        </span>
        <span class="hide-on-small">
          <a [routerLink]="['/privacy-policy']">Privacy Policy</a> |
          <a [routerLink]="['/terms-of-service']">Terms of Service</a>
        </span>
      </div>
      <div class="col-6 text-end">
        <button class="btn btn-chat" (click)="toggleChat()">
          <i class="mdi mdi-message-text-outline me-1"></i>
          <span>Chat with AI</span>
        </button>
      </div>
    </div>
  </div>
</footer>
