<div class="chat-container" *ngIf="isChatVisible">
  <div class="chat-header">
    <h5>Chat</h5>
    <div class="chat-actions">
      <button type="button" class="btn btn-sm btn-outline-danger" (click)="clearHistory()">Clear History</button>
      <button type="button" class="btn-close" aria-label="Close" (click)="toggleChat()"></button>
    </div>
  </div>
  <div #chatHistory class="chat-history">
    <ng-container *ngFor="let message of chatHistory$ | async">
      <div class="message" [ngClass]="{'user-message': message.role === 'user', 'assistant-message': message.role === 'assistant'}">
        <div class="message-content" [innerHTML]="formatMessage(message.content)"></div>
      </div>
    </ng-container>
    <div *ngIf="currentResponse" class="message assistant-message current-response">
      <div class="message-content" [innerHTML]="formatMessage(currentResponse)"></div>
    </div>
  </div>

  <form [formGroup]="chatForm" (ngSubmit)="onSubmit()" class="chat-form">
    <textarea #messageInput formControlName="message" placeholder="Type your message..." class="form-control"></textarea>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" id="useEntireBookContext" formControlName="useEntireBookContext">
      <label class="form-check-label" for="useEntireBookContext">Use entire book as context</label>
    </div>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" id="highQuality" formControlName="highQuality">
      <label class="form-check-label" for="highQuality">High quality</label>
    </div>
    <div class="button-container">
      <button type="submit" [disabled]="!chatForm.valid || isLoading" class="btn btn-primary">
        {{ isLoading ? 'Sending...' : 'Send' }} Max ({{ costIndication?.maxCost || 0 }} quills)
      </button>
      <button *ngIf="isLoading" type="button" (click)="abortGeneration()" class="btn btn-warning">
        Abort
      </button>
    </div>
  </form>
</div>
