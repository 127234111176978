<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-8 col-xl-8">
        <div class="card overflow-hidden">
          <div class="bg-primary-subtle">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Privacy Statement</h5>
                  <p>Your privacy is crucial while weaving stories with Talespinner.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/verification-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo-simple-coloured.svg" alt="" width="40">
                  </span>
                </div>
              </a>
            </div>
            <div class="p-4">
              <h2>Privacy Policy for www.Talespinner.io</h2>

              <p>At Talespinner, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices concerning the collection, use, and safeguarding of your data when you use our AI-powered storytelling platform.</p>

              <section class="mt-4">
                <b>1. Information Collection:</b>
                <ul>
                  <li><strong>Account Information</strong>: When you register, we collect your email address to create and manage your account, and to send you important updates or confirmations.</li>
                  <li><strong>Payment Information</strong>: If you purchase Quills, our virtual currency, we collect payment information. This is processed securely through our payment provider, Stripe. Please refer to <a href="https://stripe.com/nl/privacy" target="_blank">Stripe's Privacy Policy</a> for more information on how they handle your payment data.</li>
                  <li><strong>Usage Information</strong>: We collect data about your interactions with our service, including the AI models you use, the number of Quills spent, and general usage patterns.</li>
                  <li><strong>User-Generated Content</strong>: The stories, characters, plots, and other content you create using our platform are stored to provide you with the service.</li>
                </ul>
              </section>

              <section class="mt-4">
                <b>2. Use of Information:</b>
                <ul>
                  <li>To provide and maintain our service, including generating AI-powered content based on your inputs.</li>
                  <li>To process your payments for Quills.</li>
                  <li>To send you service updates, important notifications, or to communicate regarding your account.</li>
                  <li>To improve and optimize our service, including refining our AI models and user experience.</li>
                  <li>To detect, prevent, and address technical issues or potential misuse of our service.</li>
                </ul>
              </section>

              <section class="mt-4">
                <b>3. Content Privacy:</b>
                <ul>
                  <li>The content of your stories remains your private property unless you choose to make a project public.</li>
                  <li>Public projects may be visible to other users on the platform when all chapters are finished.</li>
                  <li>We do not claim ownership over the content you generate using our service.</li>
                </ul>
              </section>

              <section class="mt-4">
                <b>4. Data Sharing:</b>
                <ul>
                  <li>We may share anonymized, aggregated data with our AI model providers to improve the quality of the service.</li>
                  <li>We do not sell your personal information or the content of your stories to third parties.</li>
                  <li>We may share information if required by law or to protect our rights or the rights of others.</li>
                  <li>When you use our service, your inputs may be processed by our AI service providers. Please refer to their respective privacy policies for more information:
                    <ul>
                      <li><a href="https://www.anthropic.com/legal/privacy" target="_blank">Anthropic's Privacy Policy</a></li>
                      <li><a href="https://privacy.openai.com/policies" target="_blank">OpenAI's Privacy Policy</a></li>
                      <li><a href="https://openrouter.ai/privacy" target="_blank">OpenRouter's Privacy Policy</a></li>
                    </ul>
                  </li>
                </ul>
              </section>

              <section class="mt-4">
                <b>5. Data Security:</b>
                <ul>
                  <li>We employ robust security measures to protect your personal information and story content from unauthorized access, disclosure, alteration, or destruction.</li>
                  <li>While we strive to use commercially acceptable means to protect your data, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</li>
                </ul>
              </section>

              <section class="mt-4">
                <b>6. Your Rights and Choices:</b>
                <ul>
                  <li>You can access, update, or delete your personal information at any time through your account settings.</li>
                  <li>You can choose to make your projects public or keep them private.</li>
                  <li>You can opt-out of marketing communications while still receiving essential service notifications.</li>
                </ul>
              </section>

              <section class="mt-4">
                <b>7. Children's Privacy:</b>
                <p>Talespinner is intended for users who are at least 13 years old. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13, please contact us immediately.</p>
              </section>

              <section class="mt-4">
                <b>8. Changes to Privacy Policy:</b>
                <ul>
                  <li>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "last modified" date.</li>
                  <li>For significant changes, we will provide a more prominent notice, which may include an email notification.</li>
                </ul>
              </section>

              <section class="mt-4 mb-5">
                <b>9. Contact Us:</b>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:privacy@talespinner.io">privacy@talespinner.io</a>.</p>
              </section>

              <p>By using Talespinner, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
            </div>
          </div>
        </div>

        <div class="mt-5 text-center">
          <p>Back to the <a routerLink="/account/signup" class="fw-medium text-primary"> Registration form</a></p>
          <p>© {{year}} Talespinner.</p>
        </div>
      </div>
    </div>
  </div>
</div>
