<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-8 col-xl-8">
        <div class="card overflow-hidden">
          <div class="bg-primary-subtle">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Terms of service</h5>
                  <p>What you need to know before using Talespinner.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/verification-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo-simple-coloured.svg" alt="" width="40">
                  </span>
                </div>
              </a>
            </div>
            <div class="p-4">
              <h2>Terms & Conditions for talespinner.io</h2>

              <section class="mt-4">
                <b>1. Acceptance of Terms:</b>
                <p>By accessing or using talespinner.io (the "Website"), owned by Bons AI ("Company"), you ("User" or "Customer") agree to these Terms & Conditions. If you do not agree, you must refrain from using this Website.</p>
              </section>

              <section class="mt-4">
                <b>2. Intellectual Property:</b>
                <p>Users warrant that any content they create or upload does not violate any third-party intellectual property rights. The Company shall not be held liable for any such infringements. Should any third-party claims arise due to such violations, they will be directed to and recouped from the User.</p>
              </section>

              <section class="mt-4">
                <b>3. Amendments to Terms & Conditions:</b>
                <p>The Company reserves the right to update or change these Terms & Conditions periodically. It's the User's responsibility to review and adhere to these changes to continue accessing the Website.</p>
              </section>

              <section class="mt-4">
                <b>4. User Eligibility:</b>
                <p>By agreeing to these terms, the User confirms they are at least 13 years old or have attained the minimum legal age in their jurisdiction. Users below this age need parental or guardian consent, whereby the terms apply to the consenting adult.</p>
              </section>

              <section class="mt-4">
                <b>5. Termination of Service:</b>
                <p>The Company reserves the right to terminate any User's access or collaboration at any point, without notice.</p>
              </section>

              <section class="mt-4">
                <b>6. Artificial Intelligence Disclaimer:</b>
                <p>Users acknowledge that artificial intelligence is a nascent technology. The Company cannot guarantee the accuracy or desirability of content generated. Furthermore, there's a possibility the generated content may not align with a User's preference or intent.</p>
              </section>

              <section class="mt-4">
                <b>7. Third-party AI Software Providers:</b>
                <p>Besides agreeing to these terms, Users also acknowledge and agree to the terms and conditions set by our AI software partners:</p>
                <ul>
                  <li><a href="https://console.anthropic.com/legal/terms">Claude (Claude's Terms)</a></li>
                  <li><a href="https://openai.com/policies">ChatGPT (ChatGPT's Terms)</a></li>
                  <li><a href="https://openrouter.ai/terms">OpenRouter (OpenRouter's Terms)</a></li>
                </ul>
              </section>

              <section class="mt-4">
                <b>8. Content Removal:</b>
                <p>The Company reserves the right to remove any content submitted by the User or generated on the User's behalf, without stating reasons.</p>
              </section>

              <section class="mt-4">
                <b>9. Limitation of Liability:</b>
                <p>Users understand the Company is not responsible for any loss of profit, goodwill, or data. It's the User's duty to manage and store their data properly.</p>
              </section>

              <section class="mt-4">
                <b>10. Severability:</b>
                <p>If any provision of these terms is found invalid, the remaining provisions remain in effect.</p>
              </section>

              <section class="mt-4">
                <b>11. Force Majeure:</b>
                <p>The Company is not liable for any failure or delay in fulfilling any of its service obligations caused by events outside its reasonable control.</p>
              </section>

              <section class="mt-4">
                <b>12. Governing Law & Jurisdiction:</b>
                <p>Any disputes arising from these terms shall be resolved in the Arnhem court, Netherlands, with Dutch law being applicable.</p>
              </section>

              <section class="mt-4">
                <b>13. User-Generated Content:</b>
                <p>Users retain ownership of any original content they create using our service. However, by using talespinner.io, users grant the Company a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute the content generated through our platform for the purposes of providing and improving our services.</p>
              </section>

              <section class="mt-4">
                <b>14. Data Privacy and Security:</b>
                <p>The Company is committed to protecting user privacy and data. Users should refer to our separate Privacy Policy for details on how we collect, use, and protect personal information. Users are responsible for ensuring the security of their account credentials.</p>
              </section>

              <section class="mt-4">
                <b>15. Acceptable Use Policy:</b>
                <p>Users agree not to use talespinner.io to generate or distribute content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy, or otherwise objectionable. The Company reserves the right to remove such content and terminate accounts of users who violate this policy.</p>
              </section>

              <section class="mt-4">
                <b>16. Payment and Refunds:</b>
                <p>Details about our pricing, payment terms, and refund policy should be clearly outlined. Include information about subscription models, if applicable, and the process for cancellation.</p>
              </section>

              <section class="mt-4">
                <b>17. Disclaimer of Warranties:</b>
                <p>The Company provides the service on an "as is" and "as available" basis, without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
              </section>

              <section class="mt-4">
                <b>18. Indemnification:</b>
                <p>Users agree to indemnify and hold harmless the Company, its officers, directors, employees, and agents from any claims, damages, losses, liabilities, and expenses (including legal fees) arising out of or related to their use of talespinner.io or any violation of these Terms & Conditions.</p>
              </section>

              <section class="mt-4">
                <b>19. Copyright Infringement Reporting:</b>
                <p>If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible on this site, please contact us at <a href="mailto:copyright@talespinner.io">copyright@talespinner.io</a> with the following information:</p>
                <ul>
                  <li>A description of the copyrighted work that you claim has been infringed;</li>
                  <li>The URL or other specific location on our site where the material that you claim is infringing is located;</li>
                  <li>Your address, telephone number, and email address;</li>
                  <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
                  <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
                </ul>
              </section>

              <section class="mt-4 mb-5">
                <b>20. Account Security:</b>
                <p>Users are responsible for maintaining the confidentiality of their account information and password. They agree to notify the Company immediately of any unauthorized use of their account or any other breach of security.</p>
              </section>

              <p>By using talespinner.io, you acknowledge that you have read, understood, and agree to be bound by these Terms & Conditions.</p>
            </div>
          </div>
        </div>

        <div class="mt-5 text-center">
          <p>Back to the <a routerLink="/account/signup" class="fw-medium text-primary"> Registration form</a></p>
          <p>© {{year}} Talespinner.</p>
        </div>
      </div>
    </div>
  </div>
</div>
