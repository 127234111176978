import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: []
})
export class PrivacyComponent implements OnInit {

  year: number = new Date().getFullYear();

  constructor(
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Privacy - Talespinner.io | Flexible AI-Powered Storytelling');
  }

}
