import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.service';
import { Account } from '../models/authentication.model';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {
  private baseUrl = 'accounts';

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) {}

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${this.baseUrl}/${route}`;
  }

  getAccountFromApi(): Observable<Account> {
    return this.http.get<Account>(this.createCompleteRoute('', this.envUrl.apiUrl));
  }
}
