import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: []
})
export class TermsComponent implements OnInit {

  year: number = new Date().getFullYear();

  constructor(
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Terms & Conditions - Talespinner.io | Flexible AI-Powered Storytelling');
  }
}
