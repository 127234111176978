<div class="background"></div>

<div class="container-fluid content d-flex flex-column">
  <nav class="navbar">
    <a [routerLink]="['/']" [ngClass]="{selected: activeUrl === '/'}">Home</a>
    <a [routerLink]="['/about']" [ngClass]="{selected: activeUrl === '/about'}">About</a>
    <a [routerLink]="['/features']" [ngClass]="{selected: activeUrl === '/features'}">Features</a>
    <a [routerLink]="['/pricing']" [ngClass]="{selected: activeUrl === '/pricing'}">Pricing</a>
    <a [routerLink]="['/discover']" [ngClass]="{selected: activeUrl === '/discover'}">Discover Books</a>
    <a [routerLink]="['/dashboard']" *ngIf="userIsLoggedIn">To Dashboard</a>
    <a [routerLink]="['/account/login']" [ngClass]="{selected: activeUrl && activeUrl.startsWith('/account')}" *ngIf="!userIsLoggedIn">Login /
      Register</a>
    <a href="https://discord.gg/mFyF3ePqMF" target="_blank">
      <i class="fab fa-discord font-size-24"></i> Join our Discord
    </a>
  </nav>

  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>

  <footer class="landing-footer">
    <div class="container">
      <p>&copy; {{currentYear}} Talespinner.
        <span style="margin-left: 8px; margin-right: 8px; cursor: pointer;"
              tooltip="Operated by Bons AI
                       KVK: 91727286 | BTW: 067952045B01
                       Raapopseweg 74, 6824DT Arnhem
                       contact@talespinner.io"
              placement="top"
              triggers="click">
          <i class="fa fa-info-circle"></i>
        </span>
      </p>
      <div>
        <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
        <a [routerLink]="['/terms-of-service']">Terms of Service</a>
      </div>
    </div>
  </footer>
</div>
