export const environment = {
  production: false,
  clientUrl: 'https://staging.talespinner.io',
  apiUrl: 'https://api.staging.talespinner.io/api',
  jwtDomain: 'api.staging.talespinner.io',
  allowSearchEngineIndexing: false,
  sentry : {
    showDialog: true,
    enabled: true,
    dsn: "https://f33a2e36a842fb68ccef7f05c8ad3caf@o4506088050917376.ingest.sentry.io/4506089110175744",
    tracePropagationTargets: ["https://api.staging.talespinner.io/api", "https://staging.talespinner.io"],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  }
};
