import {map} from 'rxjs/operators';
import {WalletApiService} from "./wallet-api.service";
import {BehaviorSubject, firstValueFrom, lastValueFrom, Observable, of} from "rxjs";
import {WalletBalance} from "../models/wallet.model";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private walletBalanceSubject = new BehaviorSubject<WalletBalance | null>(null);
  public totalCredits$: Observable<number | null>;

  constructor(private walletApiService: WalletApiService) {
    this.totalCredits$ = this.walletBalanceSubject.asObservable().pipe(
      map(wallet => wallet ? wallet.totalCredits : null)
    );
    this.fetchWalletFromApiAndNotifyListeners(); // Fetch initial data
  }

  subtractCreditsLocally(amount: number): void {
    const currentBalance = this.walletBalanceSubject.value;
    if (currentBalance) {
      this.walletBalanceSubject.next({
        ...currentBalance,
        totalCredits: currentBalance.totalCredits - amount
      });
    }
  }

  public fetchWalletFromApiAndNotifyListeners(): void {
    this.walletApiService.getWalletBalanceForCurrentUser().subscribe(
      walletBalance => {
        this.walletBalanceSubject.next(walletBalance);
      }
    );
  }

  async getBalance(): Promise<number | null> {
    this.totalCredits$.subscribe({
      next: totalCredits => {
        if (totalCredits !== null) {
          return totalCredits;
        } else {
          console.error('Error getting balance');
          return null;
        }
      },
      error: error => {
        console.error('Error getting balance', error);
        return null;
      }
    });
    return null;
  }
}
