import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, of, tap} from "rxjs";
import {ProfilePictureDto} from "../models/profile-picture.model";
import {HttpClient} from "@angular/common/http";
import {EnvironmentUrlService} from "./environment-url.service";
import {catchError} from "rxjs/operators";
import * as Sentry from "@sentry/browser";

@Injectable({
  providedIn: 'root'
})
export class ProfilePictureService {
  private baseUrl = 'users/profile-picture';

  // Use a BehaviorSubject with an initial value (null in this case).
  private profilePictureSubject = new BehaviorSubject<ProfilePictureDto | null>(null);
  profilePicture$ = this.profilePictureSubject.asObservable();

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) {
    // Load the initial value from local storage when the service is instantiated
    const cachedProfilePicture = localStorage.getItem('cachedProfilePicture');
    if (cachedProfilePicture) {
      this.profilePictureSubject.next(JSON.parse(cachedProfilePicture));
    }
  }

  private createCompleteRoute(route: string, envAddress: string): string {
    return `${envAddress}/${this.baseUrl}/${route}`;
  }

  public uploadProfilePicture(profilePictureDto: ProfilePictureDto): Observable<any> {
    return this.http.post(this.createCompleteRoute('upload', this.envUrl.apiUrl), profilePictureDto)
      .pipe(
        tap(() => {
          localStorage.removeItem('cachedProfilePicture');
          this.fetchAndNotify();
        })
      );
  }

  public getProfilePicture(forceFetch = false): Observable<ProfilePictureDto | null> {
    if (forceFetch || !this.profilePictureSubject.value) {
      this.fetchAndNotify();
    }
    return this.profilePicture$;
  }

  private fetchAndNotify(): void {
    this.http.get<ProfilePictureDto>(this.createCompleteRoute('', this.envUrl.apiUrl))
      .pipe(
        catchError((error: any, caught: Observable<ProfilePictureDto>) => {
          if (error.status === 404) {
            localStorage.removeItem('cachedProfilePicture');
            this.profilePictureSubject.next(null);
          } else {
            Sentry.captureException(error);
          }
          return of(null);  // Return an Observable of null so the outer subscription won't fail
        })
      )
      .subscribe(profilePicture => {
        if (profilePicture) {
          localStorage.setItem('cachedProfilePicture', JSON.stringify(profilePicture));
          this.profilePictureSubject.next(profilePicture);
        }
      });
  }

}

