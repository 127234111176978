import {Injectable} from "@angular/core";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class TalespinnerGoogleAnalyticsService {

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {
  }

  public event(action: string, category: string = undefined, label: string = undefined, value: number = undefined, interaction: boolean = true, metadata: object = undefined): void {
    if (environment.production) {
      this.googleAnalyticsService.event(action, category, label, value);
    } else {
      console.log('Event. action:', action, 'data:', metadata);
    }
  }

  public pageView(url: string, title: string): void {
    if (environment.production) {
      this.googleAnalyticsService.pageView(url, title, url);
    } else {
      console.log('Page view. url:', url, 'title:', title);
    }
  }
}
