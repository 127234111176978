import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {UserService} from "../../core/services/user.service";

@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss']
})
export class LandingLayoutComponent implements OnInit {

  activeUrl: string;
  userIsLoggedIn: any;
  currentYear: number = new Date().getFullYear();

  constructor(private router: Router){
  }

  ngOnInit() {
    this.router.events.subscribe((res) => {
      this.activeUrl = this.router.url;
    });

    this.userIsLoggedIn = !!localStorage.getItem('token');
  }
}
