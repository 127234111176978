import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account } from '../models/authentication.model';
import { AccountApiService } from './account-api.service';  // Don't forget to import the new service

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private accountSubject: BehaviorSubject<Account | null> = new BehaviorSubject<Account | null>(null);
  public account$: Observable<Account | null> = this.accountSubject.asObservable();

  constructor(private accountApiService: AccountApiService) {}  // Inject the new API service

  getAccount(): Observable<Account | null> {
    const cachedAccount = this.accountSubject.getValue();
    if (cachedAccount) {
      return this.account$;
    } else {
      this.refreshAccount();
      return this.account$;
    }
  }

  refreshAccount(): void {
    this.accountApiService.getAccountFromApi().subscribe(account => {
      this.accountSubject.next(account);
    });
  }

  clearAccountData() {
    this.accountSubject.next(null);
  }
}
