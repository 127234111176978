<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark pt-10">
          <span class="logo-sm">
            <img src="assets/images/logo-simple-coloured.svg" alt="" height="40">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark-with-text.svg" alt="" width="45">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light pt-10">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="" height="40">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light-with-text.svg" alt="" height="45">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" dropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true" dropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" *dropdownMenu
             aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..."
                       aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" [routerLink]="['/dashboard/wallet/buy']" data-bs-toggle="tooltip" placement="bottom" tooltip="You can spend quills to use various AI features such as generating plots, characters, and writing chapters.">
          <i class="mdi mdi-feather font-size-24"></i>
          <span>{{totalCredits}}</span>
        </button>

        <button type="button" class="btn header-item" dropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" [src]="profilePictureUrl || 'assets/images/users/user-dummy-img.jpg'"
               alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{account?.firstName}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/dashboard/user/profile"><i
            class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>

          <a class="dropdown-item" [routerLink]="['/dashboard/wallet']">
            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>

          <div class="dropdown-divider"></div>

          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
            class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <!--         <div class="dropdown d-inline-block">-->
      <!--            <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">-->
      <!--               <i class="bx bx-cog bx-spin"></i>-->
      <!--            </button>-->
      <!--         </div>-->

    </div>
  </div>
</header>
