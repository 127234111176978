import { Component, Inject, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { environment } from "../environments/environment";
import { TalespinnerGoogleAnalyticsService } from "./core/services/talespinner-google-analytics.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subs: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gaService: TalespinnerGoogleAnalyticsService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private metaService: Meta,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.addRobotsConfigurationToMetaTag();
    this.setupDynamicSEO();
    this.addStructuredData();
    this.addAnalytics();
  }

  private addRobotsConfigurationToMetaTag() {
    const meta = this.renderer.createElement('meta');
    const content = environment.allowSearchEngineIndexing ? 'index, follow' : 'noindex, nofollow';
    this.renderer.setAttribute(meta, 'name', 'robots');
    this.renderer.setAttribute(meta, 'content', content);
    this.renderer.appendChild(this.document.head, meta);
  }

  private setupDynamicSEO() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary')
    ).subscribe((route) => {
      const seoData = route.snapshot.data['seo'] || {};
      this.updateMetaTags(seoData);
    });
  }

  private updateMetaTags(seoData: any) {
    const title = seoData.title || 'Talespinner - AI-Powered Creative Writing Platform';
    const description = seoData.description || 'Talespinner: AI-powered writing assistant for novelists of all levels. Craft unique stories, develop characters, and explore plots with cutting-edge AI guidance.';
    const image = seoData.image || 'https://talespinner.io/assets/images/promotional10-edited-2.webp';
    const url = this.router.url;

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });

    // OpenGraph
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: image });
    this.metaService.updateTag({ property: 'og:url', content: `https://talespinner.io${url}` });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({ property: 'og:site_name', content: 'Talespinner' });

    // Twitter
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
    this.metaService.updateTag({ name: 'twitter:image', content: image });
    this.metaService.updateTag({ name: 'twitter:site', content: '@TalespinnerIO' });
    this.metaService.updateTag({ name: 'twitter:creator', content: '@TalespinnerIO' });

    // Update canonical URL
    const link: HTMLLinkElement = this.document.querySelector('link[rel="canonical"]') || this.document.createElement('link');
    link.rel = 'canonical';
    link.href = `https://talespinner.io${url}`;
    if (!this.document.querySelector('link[rel="canonical"]')) {
      this.document.head.appendChild(link);
    }

    // Update keywords
    this.metaService.updateTag({
      name: 'keywords',
      content: 'Talespinner, AI storytelling, novel writing, book creation, creative writing, narrative crafting, character development, plot outlining, world-building, multilingual writing, AI-assisted writing, storytelling platform, book writing software, author tools, fiction writing, experienced writers, aspiring novelists, writing assistant'
    });
  }

  private addStructuredData() {
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'application/ld+json');
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Talespinner",
      "url": "https://talespinner.io",
      "description": "AI-powered novel writing and book creation platform for both experienced writers and aspiring novelists. Craft unique stories, develop characters, and explore intricate plotlines in any genre or language.",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://talespinner.io/search?q={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    };
    script.textContent = JSON.stringify(structuredData);
    this.renderer.appendChild(this.document.head, script);
  }

  private addAnalytics() {
    const sanitizeUrl = (url: string): string => {
      return url.replace(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g, '{uuid}')
        .replace(/\d+/g, '{id}');
    };

    this.subs = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      map(route => {
        const pageTitle = route.snapshot.data.title;
        const urlAfterRedirects = this.router.url;
        return {pageTitle, urlAfterRedirects};
      }),
      map(({urlAfterRedirects, pageTitle}) => {
        const sanitizedUrl = sanitizeUrl(urlAfterRedirects);
        return {sanitizedUrl, pageTitle};
      })
    ).subscribe(({sanitizedUrl, pageTitle}) => {
      if (pageTitle) {
        this.gaService.pageView(sanitizedUrl, pageTitle);
      } else {
        this.gaService.pageView(sanitizedUrl, "Talespinner");
      }
    });
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
