import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EnvironmentUrlService } from './environment-url.service';
import {
  AuthResponseDto,
  ForgotPasswordDto,
  ResetPasswordDto,
  User,
  UserForAuthenticationDto,
  UserForRegistrationDto
} from '../models/authentication.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = 'users';
  private authChangeSub = new Subject<boolean>();
  public authChanged = this.authChangeSub.asObservable();

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService, private jwtHelper: JwtHelperService) {}

  private createCompleteRoute(route: string, envAddress: string): string {
    return `${envAddress}/${this.baseUrl}/${route}`;
  }

  public getUser(): Observable<User> {
    return this.http.get<User>(this.createCompleteRoute('', this.envUrl.apiUrl));
  }

  public loginUser(body: UserForAuthenticationDto): Observable<AuthResponseDto> {
    return this.http.post<AuthResponseDto>(this.createCompleteRoute('login', this.envUrl.apiUrl), body);
  }

  public registerUser(body: UserForRegistrationDto): Observable<any> {
    return this.http.post(this.createCompleteRoute('register', this.envUrl.apiUrl), body);
  }

  public forgotPassword(body: ForgotPasswordDto): Observable<any> {
    return this.http.post(this.createCompleteRoute('forgot-password', this.envUrl.apiUrl), body);
  }

  public resetPassword(body: ResetPasswordDto): Observable<any> {
    return this.http.post(this.createCompleteRoute('reset-password', this.envUrl.apiUrl), body);
  }

  public confirmEmail(userId: string, token: string): Observable<any> {
    return this.http.post(this.createCompleteRoute('confirm-email', this.envUrl.apiUrl), { userId, token }, { responseType: 'text' });
  }

  public resendEmailConfirmation(email: string): Observable<any> {
    return this.http.post(this.createCompleteRoute('resend-email-confirmation', this.envUrl.apiUrl), { email });
  }

  public logout(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.sendAuthStateChangeNotification(false);
  }

  public sendAuthStateChangeNotification(isAuthenticated: boolean): void {
    this.authChangeSub.next(isAuthenticated);
  }

  refreshToken(): Observable<AuthResponseDto> {
    const refreshToken = localStorage.getItem("refreshToken");
    return this.http.post<AuthResponseDto>(this.createCompleteRoute('refresh-token', this.envUrl.apiUrl), { token: refreshToken }).pipe(
      tap(res => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("refreshToken", res.refreshToken);
        this.sendAuthStateChangeNotification(true);
      })
    );
  }

  public isUserAuthenticated(): Observable<boolean> {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      return of(false);
    }

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return of(true);
    }

    // If token is missing or expired but we have a refresh token, try to refresh
    return this.refreshToken().pipe(
      switchMap(() => of(true)),
      catchError(() => {
        this.logout();
        return of(false);
      })
    );
  }

  public isUserAdmin(): boolean {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      return role === 'Administrator';
    }
    return false;
  }
}
