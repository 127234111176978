import { Component, Input, OnInit, NgZone, OnDestroy } from '@angular/core';
import { BookProjectApiService } from '../../../core/services/book-project-api.service';
import { ActivatedRoute } from '@angular/router';
import { NgForOf, NgIf } from "@angular/common";
import { SharedBookProjectDto } from "../../../core/models/book-project.model";
import { Meta, Title } from "@angular/platform-browser";
import { EnvironmentUrlService } from "../../../core/services/environment-url.service";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-book-project-viewer',
  standalone: true,
  templateUrl: './book-project-viewer.component.html',
  imports: [
    NgIf,
    NgForOf
  ],
  styleUrls: ['./book-project-viewer.component.scss']
})
export class BookProjectViewerComponent implements OnInit, OnDestroy {
  @Input() projectId: string;
  sharedBookProject: SharedBookProjectDto;
  currentChapter: number = 0;
  isScrolling: boolean = false;
  private scrollTimeout: any;
  private destroy$ = new Subject<void>();

  constructor(
    private meta: Meta,
    private title: Title,
    private envUrl: EnvironmentUrlService,
    private bookProjectApiService: BookProjectApiService,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    if (!this.projectId) {
      this.projectId = this.route.snapshot.paramMap.get('id');
    }
    this.loadBookProject();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadBookProject() {
    this.bookProjectApiService.getSharedBookProject(this.projectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (project) => {
          this.sharedBookProject = project;
          this.updateMetaTags(project);
          this.setupScrollListener();
        },
        error: (error) => {
          console.error('Error loading shared book project:', error);
        }
      });
  }

  updateMetaTags(project: SharedBookProjectDto) {
    this.title.setTitle(`${project.title} - Talespinner.io | AI-Powered Storytelling Platform`);
    this.meta.updateTag({ name: 'description', content: 'Read and explore a shared book project on Talespinner.io. Discover new stories and writing projects created by our community of writers and storytellers.' });
    this.meta.updateTag({ name: 'keywords', content: 'Talespinner shared book project, community stories, AI-powered storytelling, creative writing platform' + project.title });
    let coverUrl = this.envUrl.apiUrl + `/book-project/${project.id}/cover`;
    this.meta.updateTag({ property: 'og:image', content: coverUrl });
    this.meta.updateTag({ name: 'twitter:title', content: project.title });
    this.meta.updateTag({ name: 'twitter:description', content: 'Shared book project on Talespinner.io' });
    this.meta.updateTag({ name: 'twitter:image', content: coverUrl });
    this.meta.updateTag({ name: 'twitter:site', content: '@TalespinnerIO' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@TalespinnerIO' });
  }

  scrollToChapter(event: Event, index: number) {
    event.preventDefault();
    const chapterElement = document.getElementById(`chapter-${index}`);
    if (chapterElement) {
      this.isScrolling = true;
      chapterElement.scrollIntoView({ behavior: 'smooth' });
      this.currentChapter = index;
    }
  }

  splitIntoParagraphs(text: string): string[] {
    return text.split(/\n{2,}/).map(paragraph => paragraph.trim()).filter(Boolean);
  }

  preserveNewlines(text: string): string {
    return text.replace(/\n/g, '<br>');
  }

  setupScrollListener() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', () => {
        if (this.scrollTimeout) {
          clearTimeout(this.scrollTimeout);
        }

        this.scrollTimeout = setTimeout(() => {
          this.ngZone.run(() => {
            this.isScrolling = false;
            this.updateCurrentChapter();
          });
        }, 100);
      });
    });
  }

  updateCurrentChapter() {
    if (!this.isScrolling && this.sharedBookProject && this.sharedBookProject.chapters) {
      const chapters = this.sharedBookProject.chapters;
      for (let i = chapters.length - 1; i >= 0; i--) {
        const chapterElement = document.getElementById(`chapter-${i}`);
        if (chapterElement && chapterElement.getBoundingClientRect().top <= 100) {
          this.currentChapter = i;
          break;
        }
      }
    }
  }
}
