import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreditsService {

  constructor(private toastr: ToastrService, private router: Router) {}

  handleInsufficientCredits() {
    this.toastr.warning('You need more Quills to continue using our AI features. Click here to purchase and keep creating.', 'Not enough Quills', {
      timeOut: 8000,
      extendedTimeOut: 0,
      tapToDismiss: true
    }).onTap.pipe(
      tap(() => this.goToBuyCredits())
    ).subscribe();
  }

  private goToBuyCredits() {
    this.router.navigate(['/dashboard/wallet/buy']);
  }
}
