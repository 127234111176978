import { Component, OnInit, AfterViewInit } from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';

import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import {ChatService} from "../../core/services/chat.service";

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})

/**
 * Vertical component
 */
export class VerticalComponent implements OnInit, AfterViewInit {
  showChat = false;
  private chatSubscription: Subscription;

  isCondensed:any = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private chatService: ChatService
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove('sidebar-enable');
      }
    });
  }

  ngOnInit() {
    document.body.setAttribute('data-layout', 'vertical');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      let route = this.activatedRoute.firstChild;
      while (route.firstChild) {
        route = route.firstChild;
      }
    });

    this.chatSubscription = this.chatService.showChat$.subscribe(
      showChat => this.showChat = showChat
    );
  }

  ngOnDestroy() {
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe();
    }
  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  ngAfterViewInit() {
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }

  toggleChat() {
    this.showChat = !this.showChat;
  }

}
