import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {MaintenanceComponent} from './maintenance/maintenance.component';
import {Page404Component} from './page404/page404.component';
import {Page500Component} from './page500/page500.component';
import {ComingsoonComponent} from './comingsoon/comingsoon.component';

const routes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    data: {title: 'Maintenance'}
  },
  {
    path: 'coming-soon',
    component: ComingsoonComponent,
    data: {title: 'Coming Soon'}
  },
  {
    path: '404',
    component: Page404Component,
    data: {title: 'Page 404'}
  },
  {
    path: '500',
    component: Page500Component,
    data: {title: 'Page 500'}
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ExtrapagesRoutingModule {
}
