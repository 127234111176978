import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {TabsModule} from 'ngx-bootstrap/tabs';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AccordionModule} from 'ngx-bootstrap/accordion';

import {CarouselModule} from 'ngx-owl-carousel-o';

import {ExtrapagesModule} from './extrapages/extrapages.module';

import {LayoutsModule} from './layouts/layouts.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {environment} from '../environments/environment';
import {JwtModule} from "@auth0/angular-jwt";
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {NgxGoogleAnalyticsModule} from "ngx-google-analytics";

import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import {BookProjectViewerComponent} from "./pages/share/book-project-viewer/book-project-viewer.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons';
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.clientUrl
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem("token");
}

const analyticsModules = [];
if (environment.production) {
  analyticsModules.push(NgxGoogleAnalyticsModule.forRoot('G-C9FPGJX6X5'));
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    BookProjectViewerComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.jwtDomain],
        disallowedRoutes: []
      }
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ...analyticsModules
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.sentry.showDialog,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
})
export class AppModule {

  constructor() {
    library.add(faFacebookF, faTwitter, faLinkedinIn, faPinterestP, faRedditAlien, faWhatsapp, faTelegramPlane, faEnvelope, faCopy);
  }
}
