import {HttpClient} from "@angular/common/http";
import {EnvironmentUrlService} from "./environment-url.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Wallet, WalletBalance} from "../models/wallet.model";

@Injectable({
  providedIn: 'root'
})
export class WalletApiService {
  private readonly baseUrl = 'wallet';

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) { }

  private createCompleteRoute(route: string, envAddress: string): string {
    return `${envAddress}/${this.baseUrl}/${route}`;
  }

  getWalletForCurrentUser(): Observable<Wallet> {
    return this.http.get<Wallet>(this.createCompleteRoute("", this.envUrl.apiUrl));
  }

  getWalletBalanceForCurrentUser(): Observable<WalletBalance> {
    return this.http.get<WalletBalance>(this.createCompleteRoute("balance", this.envUrl.apiUrl));
  }
}
